import {
  Banner,
  Box,
  CardCentered,
  Container,
  Heading,
  Helmet,
  IconFindingWork,
  IconLightBulb,
  IconLwbStaff,
  Link,
  PreStyledComponents,
  PromoPanelLeftImage,
  Row,
  Section,
  StandardButton,
  Variables,
  routesObject
} from "@life-without-barriers/react-components"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { Site } from "@life-without-barriers/gatsby-common"

import Layout from "../../components/disability/Layout"
import React from "react"
import { graphql } from "gatsby"

const { GridWrapper } = PreStyledComponents

const { greyXXXLight } = Variables

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
    disabilitySupportWorker: IGatsbyImageData
    teamMembers: IGatsbyImageData
  }
}

const iconSelector = (
  type: string,
  color: string,
  height?: string
): JSX.Element | undefined => {
  if (type === "lwbstaff") {
    return <IconLwbStaff color={color} height={height} />
  }
  if (type === "findingwork") {
    return <IconFindingWork color={color} height={height} />
  }
  if (type === "lightbulb") {
    return <IconLightBulb color={color} height={height} />
  }
  return undefined
}

const bannerBreadcrumbs = [routesObject.disabilityHome]

const CarrersPage = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage,
    teamMembers
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`Careers | ${siteMetadata.title}`}
        description="Disability"
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={bannerImage}
      />
      <Layout>
        <Banner
          image={bannerImage}
          title="Careers"
          breadcrumbs={bannerBreadcrumbs}
        />

        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2}>A workforce that’s a force for good.</Heading>
              <p>
                We champion diversity, inclusivity and equality in all we do.
                Our community, of staff and the people we support, have varied
                life experiences, skills, cultures and interests.
              </p>
              <p>
                If you want a fulfilling career supporting people at an
                inclusive organisation that’s nation-wide, you’re in the right
                place.
              </p>
            </Box>
          </Row>
        </Container>
        <PromoPanelLeftImage image={teamMembers} color={greyXXXLight}>
          <div>
            <h2 className="ts-display-3 fw8">
              What are we looking for in our team members?
            </h2>
            <div className="copy mt4">
              <p>
                We’re looking for Disability Support Workers who are dedicated,
                values oriented, and people-focused. People who understand the
                rights of people living with a disability, and want to deliver a
                great service that helps people thrive.
              </p>
              <p>Sound like you?</p>
              <div className="mt4 mb2">
                <Link
                  className="link normal"
                  to="https://careers.lwb.org.au/en/filter/?job-mail-subscribe-privacy=agree&search-keyword=&category=disability"
                  title="Search opportunities"
                >
                  <StandardButton className="w-100 w-auto-ns" shade="dark">
                    Search opportunities
                  </StandardButton>
                </Link>
              </div>
            </div>
          </div>
        </PromoPanelLeftImage>

        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading
                size={3}
                topMargin="none"
                className="color-lwb-theme-medium"
              >
                About being a Disability Support Worker
              </Heading>
              <p>
                We deliver supports in our shared accommodations, however most
                of the people we support live in their own homes.
              </p>
              <p>
                Our supports include a range of day-to-day activities, such as
                preparing meals, assisting in the bathroom and engaging in the
                community in a way that’s safe and enjoyable.
              </p>
              <p>
                We want to help the people we support build on their existing
                skills, and live life their way.
              </p>
            </Box>
          </Row>
        </Container>
        <Section background="bg-hex-image">
          <Container>
            <Row>
              <Box>
                <Heading size={2} className="tc color-lwb-theme-medium">
                  Are you living with a disability and looking for work?
                </Heading>
                <GridWrapper className="mt4 flex-ns flex-wrap-ns">
                  <CardCentered
                    selectIcon={iconSelector}
                    href="https://careers.lwb.org.au/en/filter/?job-mail-subscribe-privacy=agree&search-keyword=&category=disability"
                    iconHeight="60"
                    icon="lwbstaff"
                    title="Work with us"
                    copy="We know that you’ll bring a unique set of skills that’ll benefit the people we support."
                    actionLabel="Find out more"
                    showReadMore
                  />
                  <CardCentered
                    selectIcon={iconSelector}
                    href={
                      "/disability/services/young-peoples-employment-pathways/"
                    }
                    iconHeight="60"
                    icon="lightbulb"
                    title="Be inspired"
                    copy="If you are aged 16-22 find out more about our disability employment services."
                    actionLabel="Learn more"
                    showReadMore
                  />
                  <CardCentered
                    selectIcon={iconSelector}
                    href="/disability/services/employment-pathways/"
                    iconHeight="60"
                    icon="findingwork"
                    title="Employment pathways"
                    copy="We work alongside you to prepare for, or to rejoin, the workforce."
                    actionLabel="Find out more"
                    showReadMore
                  />
                </GridWrapper>
              </Box>
            </Row>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerImage: file(
      relativePath: { regex: "/disabilityCareersBannerNew.jpeg/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    teamMembers: file(
      relativePath: { regex: "/disabilityCareersFeature.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
  }
`

export default CarrersPage
